import React, { useState } from 'react';
import { useNavigate,  } from 'react-router-dom';

import { useGetWatchListQuery, useDeleteMovieMutation } from '../actions/apiSlice';
import { Table, Button, Container, Row, Col } from 'react-bootstrap';
import { quantum } from 'ldrs';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { Helmet } from 'react-helmet-async';

quantum.register("l-quantum");

function WatchlistScreen() {
  const [deletingMovieId, setDeletingMovieId] = useState(null);
  const navigate = useNavigate();

  const navigateShow = (show) => {
    const query = `${show.name} ${show.year}`;
    navigate('/details/?q=' + query);
  }

  function formatDate(date) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString(undefined, options);
    const currentDate = new Date();
    const movieDate = new Date(date);

    let colorClass = '';
    if (movieDate < currentDate) {
      colorClass = 'text-success'; // Green if the movie date is before today
    } else {
      colorClass = 'text-danger'; // Red if the movie date is in the future
    }

    return <span className={colorClass}>{formattedDate}</span>;
  }

  const { data, error, isLoading } = useGetWatchListQuery();
  const [deleteMovie] = useDeleteMovieMutation();

  const handleDelete = (movieId) => {
    setDeletingMovieId(movieId);
    deleteMovie(movieId).finally(() => setDeletingMovieId(null));
  }

  return (
    <div>
             <Helmet>
  <title>Watchlist</title>

</Helmet>
      {isLoading ? (
        <Container>
          <Row className="justify-content-center align-items-center" style={{ height: '100vh' }}>
            <Col xs="auto">
              <l-quantum size="150" speed="1.5" color="white"></l-quantum>
            </Col>
          </Row>
        </Container>
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : data ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th colSpan="5" className="text-center">
                <span>Watchlist</span>
              </th>
            </tr>
            <tr>
              <th>Name</th>
              <th>ReleaseDate</th>
              <th></th> {/* An empty cell for the Delete button column */}
            </tr>
          </thead>
          <tbody>
            {data.map(movie => (
              <tr key={movie.id}>
                <td

                onClick={() => navigateShow(movie)}
                style={{ cursor: 'pointer' }}>
                  <img src={movie.small_poster_url} alt={movie.name}
                  style={{ width: '72px', height: '106px', objectFit: 'cover' }}

                  />
                  {movie.name}
                </td>
                <td>{formatDate(movie.air_date)}</td>
                <td>
                  {deletingMovieId === movie.id ? (
                    <CircularProgress size={24} />
                  ) : (
                      <RemoveCircleOutlineOutlinedIcon  onClick={() => handleDelete(movie.id)}
                  style={{ cursor: 'pointer' }}

                      />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}
    </div>
  )
}

export default WatchlistScreen;
