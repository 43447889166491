import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form, FormControl, Button, Col, Row, Container, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useLazySearchMoviesAndShowsQuery } from '../actions/tmdbApiSlice'; // Assuming the hook name

const SearchForm = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const [triggerSearch, { data }] = useLazySearchMoviesAndShowsQuery();
  const inputRef = useRef(null);

  useEffect(() => {
    if (data) {
      // Filter results to include only movies or TV shows
      const filteredResults = data.results.filter(
        (result) => result.media_type === 'movie' || result.media_type === 'tv' || result.media_type === 'person'
      );

      // Limit suggestions to maximum 5
      const limitedSuggestions = filteredResults.slice(0, 5);
      setSuggestions(limitedSuggestions);
      setShowSuggestions(true);
    }
  }, [data]);

  const debouncedSearch = useCallback(
    debounce((newQuery) => {
      if (newQuery.length > 2) {
        triggerSearch(newQuery);
      }
    }, 300),
    [triggerSearch]
  );

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    if (newQuery.length > 2) {
      debouncedSearch(newQuery);
    } else {
      debouncedSearch.cancel(); // Cancel any pending debounced calls
      setSuggestions([]); // Clear suggestions if the query is too short
      setShowSuggestions(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (query.trim().length > 0) {
      setShowSuggestions(false);

      navigate(`/search/?q=${encodeURIComponent(query.trim())}`);
    }
  };
  const handleSuggestionClick = (suggestion) => {
    setQuery('');
    let year = '';

    if (suggestion.media_type === 'movie' && suggestion.release_date) {
      year = suggestion.release_date.split('-')[0];
    } else if (suggestion.media_type === 'tv' && suggestion.first_air_date) {
      year = suggestion.first_air_date.split('-')[0];
    }

    const titleWithYear = year ? `${suggestion.title || suggestion.name} ${year}` : suggestion.title || suggestion.name;

    if (suggestion.media_type === 'person') {
      navigate(`/person/?q=${suggestion.name}`);
    } else {
      const idParam = suggestion.media_type === 'tv' ? 'tid' : 'mid';
      navigate(`/details/?q=${titleWithYear}&${idParam}=${suggestion.id}`);
    }

    setSuggestions([]); // Clear suggestions on suggestion click
    setShowSuggestions(false);
  };
  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target) && !event.target.closest('.list-group-item')) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFocus = () => {
    if (suggestions.length > 0) {
      setShowSuggestions(true);
    }
  };

  return (
    <Container className="mt-2" style={{ maxWidth: '600px', marginBottom: '1rem', position: 'relative' }}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <FormControl
              type="text"
              placeholder="Enter TV show or movie name"
              value={query}
              onChange={handleInputChange}
              onFocus={handleFocus}
              ref={inputRef}
            />
          </Col>
          <Col>
            <Button variant="secondary" type="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      {showSuggestions && suggestions.length > 0 && (
        <ListGroup
          style={{
            width: '100%',
            maxWidth: '600px',
            borderRadius: '0.5rem',
            marginBottom: '0.5rem',
            position: 'absolute',
            zIndex: 1000,
            // backgroundColor: 'white',
          }}
        >
          {suggestions.map((suggestion) => (
            <ListGroup.Item
              key={suggestion.id}
              onClick={() => handleSuggestionClick(suggestion)}
              className={`list-group-item d-flex align-items-center`}
              style={{ cursor: 'pointer' }}
            >
            {suggestion.poster_path || suggestion.profile_path ? (
  <img
    src={`https://image.tmdb.org/t/p/w154${
      suggestion.media_type === 'person' ? suggestion.profile_path : suggestion.poster_path
    }`}
    alt={`${suggestion.title || suggestion.name} poster`}
    style={{ width: '50px', height: '68px', marginRight: '10px' }}
  />
) : null}
              <div>
                {suggestion.title || suggestion.name}
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Container>
  );
};

export default SearchForm;
