import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Card, Accordion, Col, Container, Row, } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
import IconButton from '@mui/material/IconButton';
import { useSearchShowQuery, useGetWatchListQuery, useAddListMutation } from '../actions/apiSlice';
import { useGetRecommendationsQuery, } from '../actions/tmdbApiSlice';
import { useLazySearchTrailersQuery, } from '../actions/searchTrailer';
import useRedirectToLogin from '../hooks/useRedirectToLogin';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import EpisodeAvailability from './../components/EpisodeAvailability';
import FunFacts from './../components/FunFacts';
import { quantum } from 'ldrs';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { Helmet } from 'react-helmet-async';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  XIcon,
  WhatsappShareButton,
  WhatsappIcon,

} from "react-share";

const placeholderImageUrl = 'https://via.placeholder.com/102x169.png?text=Poster+Not+Available';

quantum.register('l-quantum');

const ShowDetailsScreen = () => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q');
  const mid = searchParams.get('mid');
  const tid = searchParams.get('tid');
  const { data: show, error, isFetching } = useSearchShowQuery(searchQuery);
  const { data: recommendData, error: recommendError, isFetching: recommendFetch, isSuccess: recommendSuccess } = useGetRecommendationsQuery({
    mid: mid ? mid : null,
    tid: tid ? tid : null,
  });

  const [triggerSearchTrailers, { data: trailerData, isLoading: trailerLoading, error: trailerError }] = useLazySearchTrailersQuery();
  useEffect(() => {
    if (trailerData) {
      setVideos(trailerData.items);
    }
  }, [trailerData]);

  const { data: watchList, isFetching: listFetching } = useGetWatchListQuery();
  const [addToList, { isSuccess: addListSuccess, isLoading: addListLoading, isError: addListError, error: listErrorData }] = useAddListMutation();
  const redirectToLogin = useRedirectToLogin();
  const token = localStorage.getItem('aircheck_access');
  const navigate = useNavigate();
  const [isInWatchlist, setIsInWatchlist] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [videos, setVideos] = useState([]);
  const [isFirstClick, setIsFirstClick] = useState(true);
  const [isAccordionStop, setIsAccordionStop] = useState(false);
  const recommendList = recommendData?.results;

  const handleRecommendedItemNavigate = (item) => {
    let year = '';

    if (item.media_type === 'movie' && item.release_date) {
      year = item.release_date.split('-')[0];
    } else if (item.media_type === 'tv' && item.first_air_date) {
      year = item.first_air_date.split('-')[0];
    }

    const titleWithYear = year ? `${item.title || item.name} ${year}` : item.title || item.name;
    const idParam = item.media_type === 'tv' ? 'tid' : 'mid';

    navigate(`/details/?q=${titleWithYear}&${idParam}=${item.id}`);
  };

  useEffect(() => {
    const searchShow = () => {
      setResetForm(true);
      setIsFirstClick(true)
      setIsAccordionStop(false)

      setTimeout(() => {
        setResetForm(false);
      }, 0);
    };

    searchShow();
  }, [searchQuery]);
  useEffect(() => {

    const checkWatchlist = async () => {
      if (show && show.kind && show.kind.includes('movie') && watchList) {


        const movieInWatchlist = watchList.some(item => item.title_id === show.imdbID);
        setIsInWatchlist(movieInWatchlist);
      }
    }

    if (show&&token) {
      checkWatchlist();
    }
  }, [show, watchList,token]);
  const currentUrl = window.location.href;

  useEffect(() => {


    if (addListError) {

        toast.error(`${Object.values(listErrorData.data)}`, { position: toast.POSITION.BOTTOM_CENTER });

    }

  }, [ addListError, listErrorData,])

  const processMovieTitle = (movie) => {
    const titleSeparated = movie.title.split(' ');
    const cleanTitleSeparated = removeNonAlphanumericFromArray(titleSeparated);
    const titleJoined = cleanTitleSeparated.join('-');
    return titleJoined;
  };

  const removeNonAlphanumericFromArray = (arr) => {
    return arr.map(word => word.replace(/[^a-zA-Z0-9]/g, ''));
  };

  const handleAccordionClick = () => {
    if (show && isFirstClick) {
      triggerSearchTrailers({ query: show.title, year: show.year, kind: show.kind });
      setIsFirstClick(false);
    }
    setIsAccordionStop(!isAccordionStop);

  };


  const setWatchlist = useCallback(async () => {
    if (isInWatchlist || !show) return;

    const token = localStorage.getItem('aircheck_access');
    const formatDate = (dateStr) => {
      const dateParts = dateStr.split(' ');
      const day = dateParts[0].padStart(2, '0');
      const monthNames = {
        Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
        Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12',
      };
      const monthNumber = monthNames[dateParts[1]];
      return [dateParts[2], monthNumber, day].join('-');
    };

    if (!token) {
      toast.error('Please Log in', { position: toast.POSITION.TOP_CENTER });
      redirectToLogin();

      return;
    }



    const payload = {
      name: show.title,
      title_id: show.imdbID,
      year: show.year,
      full_poster_url: show['full-size cover url'],
      small_poster_url: show['cover url'],
      air_date: formatDate(show['original air date']),
    };

    addToList(payload)

  },
    [isInWatchlist, show, navigate, addToList]);

  const convertMinutesToHoursAndMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} hours and ${remainingMinutes} minutes`;
  };

  const handlePersonNavigation = (name, director = false) => {
    if (director) {
      navigate(`/person/?q=${name}&dir=true`);
    }
    else {
      navigate(`/person/?q=${name}`);
    }

  };

  if (isFetching) {
    return (
      <Container>
        <Row className="justify-content-center align-items-center" style={{ height: '100vh' }}>
          <Col xs="auto">
            <l-quantum size="150" speed="1.5" color="white"></l-quantum>
          </Col>
        </Row>
      </Container>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (show && show.kind) {
    const fullSizeCoverUrl = show['full-size cover url'];
    const backgroundImageStyle = {
      backgroundImage: `url(${fullSizeCoverUrl})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center right',
      position: 'absolute',
      right: 5,
      bottom: 0,
      width: '50%',
      height: '90%',
      zIndex: -1,
    };

    return (
      <>
    <Helmet>
  <title>{`${show.title} (${show.year}) - Watch Online`}</title>
  <meta name="description" content={show['plot outline']} />
  <meta name="keywords" content={`${show.title}, ${show.year},  Watch Online`} />
  <meta property="og:title" content={show.title} />
  <meta property="og:description" content={show['plot outline']} />
  <meta property="og:image" content={show['cover url']} />
  <meta property="og:url" content={currentUrl} />
  <link rel="canonical" href={currentUrl} />
</Helmet>
      <div>
        <div className='back-ground-image' style={backgroundImageStyle} />
        <Row>
          <Col xs={2} md={2}></Col>
          <Col xs={8} md={8}>
          </Col>
        </Row>

        <Col xs={12} md={8}>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={4} md={3}>
                  <a
                    href={show.kind.includes('movie') && token ? `https://lookmovie2.to/movies/view/${show.imdbID}-${processMovieTitle(show)}-${show.year}` : show.kind.includes('series') && !show.kind.includes('podcast') && token ? `https://lookmovie2.to/shows/view/${show.imdbID}-${processMovieTitle(show)}-${show.year}` : ''}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={show['cover url']}
                      alt={show.title}
                      className="img-fluid"
                      style={{ cursor: 'pointer' }}
                    />
                  </a>
                </Col>
                <Col xs={8} md={9}>
                  <div style={{ position: 'relative' }}>
                    <Card.Title><h5>{show.title}</h5></Card.Title>
                    {show.kind.includes('movie') && (
                      <Tooltip title={isInWatchlist ? "In Watchlist" : "Add to Watchlist"}>
                        <IconButton
                          onClick={setWatchlist}
                          style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            backgroundColor: 'transparent',
                            border: 'none',
                            fontSize: '24px',
                            cursor: isInWatchlist ? 'not-allowed' : 'pointer',
                            color: 'white',
                          }}
                          disabled={isInWatchlist}
                        >
                          {addListLoading || listFetching ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : isInWatchlist ? (
                            <CheckCircleOutlineIcon />
                          ) : (
                            <ControlPointOutlinedIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                  {show.kind.includes('movie') ? (
                    <Card.Text>
                      <p>Rating: {show.rating}</p>
                      <p>Year: {show.year}</p>
                      <p>Director: {show.director ? (
                        <span
                          onClick={() => handlePersonNavigation(show.director[0].name, true)}
                          style={{ marginRight: '5px', cursor: 'pointer', textDecoration: 'underline' }}
                        >
                          {show.director[0].name}
                        </span>
                      ) : ''}</p>
                      <p>Genres: {show.genres.join(', ')}</p>
                      <p>Run Time: {show.runtimes ? convertMinutesToHoursAndMinutes(show.runtimes[0]) : ''}</p>
                      <p>Cast: {show.cast ? show.cast.slice(0, 6).map((member) => (
                        <span
                          key={member.name}
                          onClick={() => handlePersonNavigation(member.name)}
                          style={{ marginRight: '5px', cursor: 'pointer', textDecoration: 'underline' }}
                        >
                          {member.name}
                        </span>
                      )) : ''}</p>
                      <p>Production: {show['production companies'] ? show['production companies'].map((company) => company.name).join(', ') : ''}</p>
                    </Card.Text>
                  ) : show.kind.includes('series') && !show.kind.includes('podcast') ? (
                    <Card.Text>
                      <p>Rating: {show.rating}</p>
                      <p>Year(s): {show['series years']}</p>

                      <p>Number of Seasons: {show.seasons}</p>

                      <p>Genres: {show.genres ? show.genres.join(', ') : 'N/A'}</p>

                      <p>Cast: {show.cast ? show.cast.slice(0, 6).map((member) => (
                        <span
                          key={member.name}
                          onClick={() => handlePersonNavigation(member.name)}
                          style={{ marginRight: '5px', cursor: 'pointer', textDecoration: 'underline' }}
                        >
                          {member.name}
                        </span>
                      )) : ''}</p>
                      <p>Production: {show['production companies'] ? show['production companies'].map((company) => company.name).join(', ') : ''}</p>
                    </Card.Text>
                  ) : (
                    <Card.Text>{show.kind}</Card.Text>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col className="m-2">
          <FacebookShareButton
            url={currentUrl}
            className="social-button"
          >
            <FacebookIcon size={50} round iconFillColor="black" />
          </FacebookShareButton>

          <WhatsappShareButton
            url={currentUrl}
            className="social-button"
          >
            <WhatsappIcon size={50} round iconFillColor="black" />
          </WhatsappShareButton>

          <TwitterShareButton
            url={currentUrl}
            className="social-button"
          >
            <XIcon size={50} round />
          </TwitterShareButton>
        </Col>
        <Row>
          <Col xs={12} md={8}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header >

                <p className='accordion-header' >Plot Summary &#128214;
                </p>
                  </Accordion.Header>
                <Accordion.Body>
                  <p>{show['plot outline']}</p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>


          <p></p>

          <Col xs={12} md={8}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header > <p className='accordion-header' >{show.kind.toUpperCase()} Fun Facts &#128161;</p> </Accordion.Header>
                <Accordion.Body>
                <FunFacts show={show} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          <p></p>

          <Col xs={12} md={8}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header onClick={handleAccordionClick} >

                <p className='accordion-header' >Watch Trailer &#128250;</p>
                  </Accordion.Header>
                <Accordion.Body>

                  {trailerLoading ? (
                    <Container>
                      <Row className="justify-content-center align-items-center" style={{ height: '100vh' }}>
                        <Col xs="auto">
                          <l-quantum size="150" speed="1.5" color="white"></l-quantum>
                        </Col>
                      </Row>
                    </Container>
                  ) : (
                    videos.map((video) => (
                      <div className="player-wrapper" key={video.id.videoId}>
                        <ReactPlayer
                          className="react-player"
                          url={`https://www.youtube.com/watch?v=${video.id.videoId}`}
                          width="100%"
                          height="100%"
                          controls={true}
                          playing={isAccordionStop}
                        />
                      </div>
                    ))
                  )}
                  {trailerError && <p>Error fetching trailer: {trailerError.message}</p>}
                </Accordion.Body>

              </Accordion.Item>
            </Accordion>
          </Col>

          <Col xs={12} md={8}>
            <EpisodeAvailability show={show} resetForm={resetForm} />
          </Col>


        </Row>












        <Container className="py-4">

          <Row className="mt-4">
            <Col>
              <h5 className="mb-3">You May Also Like</h5>
              <hr />
              <Row xs={3} md={4} lg={5} className="g-4">
                {recommendList && recommendList.map(item => (
                  <Col key={item.id}>
                    <div
                      className="d-flex flex-column align-items-center"
                      onClick={() => handleRecommendedItemNavigate(item)}
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        src={item.poster_path ? `https://image.tmdb.org/t/p/w185${item.poster_path}` : placeholderImageUrl}
                        alt={`${item.title || item.name} poster`}
                        style={{ width: '102px', height: '169px', objectFit: 'cover' }}
                        className="mb-2"
                      />
                      <div className="text-center">
                        <p className="fw-bold mb-1">{item.title || item.name}</p>
                        <p className="fw-bold mb-1">Rating: {(Math.floor(item.vote_average * 10) / 10).toFixed(1)}</p>
                        <p className="text-muted">{item.release_date || item.first_air_date}</p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>




      </div>
      </>
    );
  }

  return null;
};

export default ShowDetailsScreen;
