import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSearchMoviesAndShowsQuery,  } from '../actions/tmdbApiSlice';

import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';


const placeholderImageUrl = 'https://via.placeholder.com/102x169.png?text=Poster+Not+Available';

function SearchedScreen() {

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const searchQuery = searchParams.get('q');
  const { data, error, isFetching, isSuccess } = useSearchMoviesAndShowsQuery(searchQuery);





  const handleSuggestionClick = (suggestion) => {
    let year = '';

    if (suggestion.media_type === 'movie' && suggestion.release_date) {
      year = suggestion.release_date.split('-')[0];
    } else if (suggestion.media_type === 'tv' && suggestion.first_air_date) {
      year = suggestion.first_air_date.split('-')[0];
    }

    const titleWithYear = year ? `${suggestion.title || suggestion.name} ${year}` : suggestion.title || suggestion.name;

      const idParam = suggestion.media_type === 'tv' ? 'tid' : 'mid';
      navigate(`/details/?q=${titleWithYear}&${idParam}=${suggestion.id}`);


  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }





    // Sorting function to sort by release_date or first_air_date
    const movieList = data?.results?.slice().sort((a, b) => {
      const dateA = a.release_date || a.first_air_date;
      const dateB = b.release_date || b.first_air_date;
      if (!dateA) return 1; // Handle null dates
      if (!dateB) return -1; // Handle null dates
      return new Date(dateB) - new Date(dateA); // Sort descending (latest first)
    });


  return (
    <>
    <Helmet>
    <title>{searchQuery} search results</title>

  </Helmet>
    <Container className="py-4">
      {movieList && (
        <div className="text-center mb-4">

          <h4>{searchQuery}</h4>
        </div>
      )}

      <Row className="mt-4">
        <Col>
          <hr />
          <Row xs={3} md={4} lg={5} className="g-4">
            {movieList && movieList.map(item => (
              <Col key={item.id}>
                <div
                  className="d-flex flex-column align-items-center"
                  onClick={() => handleSuggestionClick(item)}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={item.poster_path ? `https://image.tmdb.org/t/p/w185${item.poster_path}` : placeholderImageUrl}
                    alt={`${item.title || item.name} poster`}
                    style={{ width: '102px', height: '169px', objectFit: 'cover' }}
                    className="mb-2"
                  />
                  <div className="text-center">
                    <p className="fw-bold mb-1">{item.title || item.name}</p>
                    <p className="fw-bold mb-1">As {item.job || item.character}</p>
                    <p className="fw-bold mb-1">Rating: {(Math.floor(item.vote_average * 10) / 10).toFixed(1)}</p>
                    <p className="text-muted">{item.release_date || item.first_air_date}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default SearchedScreen