import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import { useNavigate } from "react-router";
import SocialLogin from './../components/SocialLogin';

function LoginScreen() {
  const token = localStorage.getItem('aircheck_access');
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_BASE_URL;

  const [isSignup, setIsSignup] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous error messages

    try {
      const url = isSignup ? `${API_BASE_URL}/api/v1/signup/` : `${API_BASE_URL}/api/token/`;
      const method = 'POST';
      const data = isSignup ? { username, email, password } : { username, password };

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (!isSignup) {
          localStorage.setItem('aircheck_access', responseData.access);
          localStorage.setItem('aircheck_refresh', responseData.refresh);
          navigate('/');
        } else {
          setIsSignup(false); // Reset the form to login after successful signup
        }
      } else {
        setError(isSignup ? "Signup Failed" : "Authentication Failed");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    if (token) {
      navigate('/');
    }
  }, [token, navigate]);

  return (
    <Row className="justify-content-center align-items-center">
      <Col xs={12} md={6}>

        <Card>
          <Card.Body>
            <h3 className="text-center">{isSignup ? "Sign Up" : "Sign In"}</h3>
            {error && <div className="alert alert-danger">{error}</div>}
            <hr />
            <SocialLogin className="text-center" isSignup={isSignup} />

            <Form className='mt-3' onSubmit={handleSubmit}>
              {isSignup && (
                <Form.Group controlId="signup-username">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>
              )}
              {isSignup && (
                <Form.Group controlId="signup-email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              )}
              {!isSignup && (
                <div>
                  <Form.Group controlId="login-username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Form.Group>
                </div>
              )}
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  required
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  value={password}
                  autoComplete="off"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <p></p>
                <Form.Check
                  type="checkbox"
                  label="Show password"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </Form.Group>

              <p></p>
              <Button type="submit" variant="secondary">
                {isSignup ? "Sign Up" : "Sign In"}
              </Button>
            </Form>

            <p className="mt-3 text-center">
              {isSignup ? 'Already have an account? ' : "Don't have an account? "}
              <Link
                to="#"
                onClick={() => setIsSignup((prevIsSignup) => !prevIsSignup)}
              >
                {isSignup ? 'Sign In' : 'Sign Up'}
              </Link>



            </p>

          </Card.Body>


        </Card>
      </Col>
    </Row>
  );
}

export default LoginScreen;
