import { Table, Carousel, Container } from 'react-bootstrap';
import { useNavigate } from "react-router";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ConfirmModal from './../components/ConfirmModal';
import { useReminderQuery, useDeleteReminderMutation } from '../actions/apiSlice';
import { quantum } from 'ldrs';
import { Helmet } from 'react-helmet-async';

quantum.register("l-quantum");

function ReminderScreen() {
  const navigate = useNavigate();

  const { data: reminders, error, isLoading: remindload } = useReminderQuery();
  const [deleteReminder] = useDeleteReminderMutation();

  const navigateShow = (show) => {
    const query = `${show.name} ${show.year}`;
    navigate('/details/?q=' + query);
  };

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  const handleDelete = (showId) => {
    deleteReminder(showId);
  };


  if (remindload) {
    return (
      <Container>
        <Row className="justify-content-center align-items-center" style={{ height: '100vh' }}>
          <Col xs="auto">
            <l-quantum size="150" speed="1.5" color="white"></l-quantum>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!reminders || reminders.length === 0) {
    return (
      <Container>
        <Row style={{ height: '100vh' }}>
          <Col xs="auto">
            <p>No reminders at the moment !!</p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div>
         <Helmet>
  <title>Reminders</title>

</Helmet>
      <Carousel>
        {reminders.map(series => (
          <Carousel.Item key={series.id}>
            <img
              className="d-block w-100"
              src={series.full_poster_url}
              alt={series.name}
              style={{ maxHeight: '300px', width: 'auto', objectFit: 'contain', cursor: 'pointer' }}
              onClick={() => navigateShow(series)}
            />
          </Carousel.Item>
        ))}
      </Carousel>

      <Table striped bordered hover>
        {reminders.length > 0 && (
          <>
            <thead>
              <tr>
                <th colSpan="5" className="text-center">
                  <span>Aired Episodes</span>
                </th>
              </tr>
              <tr>
                <th>Name</th>
                <th>Season</th>
                <th>Episode</th>
                <th>Air Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {reminders.map(airedSeries => (
                <tr key={airedSeries.id}>
                  <td onClick={() => navigateShow(airedSeries)} style={{ cursor: 'pointer' }}>
                    <img src={airedSeries.small_poster_url} alt={airedSeries.name} style={{ width: '50px' }} />
                    {airedSeries.name}
                  </td>
                  <td>{airedSeries.season}</td>
                  <td>{airedSeries.episode}</td>
                  <td>{formatDate(airedSeries.air_date)}</td>
                  <td>
                    <ConfirmModal id={airedSeries.id} onDelete={handleDelete} />
                  </td>
                </tr>
              ))}
            </tbody>
          </>
        )}
      </Table>
    </div>
  );
}

export default ReminderScreen;
