import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";

const SocialLogin = ({ isSignup }) => {
    const googleId = process.env.REACT_APP_GOOGLE_ID;
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    // Ensure the environment variable is set
    if (!googleId) {
        console.error('REACT_APP_GOOGLE_ID is not set in your environment variables.');
        return null;
    }

    const handleSuccess = async (response) => {
        try {
            const url = 'api/v1/auth/google/';
            const { credential, code } = response;

            const result = await axios.post(url, {
                access_token: credential,
                id_token: credential,
                code: code
            });

            if (result.status === 200) {
                const responseData = result.data;
                localStorage.setItem('aircheck_access', responseData.access);
          localStorage.setItem('aircheck_refresh', responseData.refresh);


                // Navigate back to the previous page
                const from = location.state?.from || '/';
                navigate(from);
            }
        } catch (err) {
            // Handle error
            console.error('Error during Google authentication:', err.response ? err.response.data : err.message);
            setError(Object.values(err.response ? err.response.data : err.message));
        }
    };

    const handleFailure = (error) => {
        console.error('Google Login failed:', error);
        setError('Google Login failed');
    };

    return (
        <GoogleOAuthProvider clientId={googleId}>
            {error && <div className="alert alert-danger">{error}</div>}
            <GoogleLogin
                onSuccess={handleSuccess}
                onError={handleFailure}
                responseType="code"
                prompt="consent"
                text='continue_with'
                useOneTap
            />
        </GoogleOAuthProvider>
    );
};

export default SocialLogin;
