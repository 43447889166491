import React, { useState, useEffect,useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Button, Col, Row, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useRedirectToLogin from '../hooks/useRedirectToLogin';
import { useAddReminderMutation, useEpisodeAvailabilityMutation } from '../actions/apiSlice';
const placeholderImageUrl = 'https://via.placeholder.com/600x400.png?text=Poster+Not+Available';

const EpisodeAvailability = ({ show, resetForm }) => {
  const redirectToLogin = useRedirectToLogin();
  const token = localStorage.getItem('aircheck_access');
  const [searchParams] = useSearchParams();
  const tid = searchParams.get('tid');

  const [seasonNumber, setSeasonNumber] = useState('');
  const [episodeNumber, setEpisodeNumber] = useState('');
  const [availabilityMessage, setAvailabilityMessage] = useState('');
  const [showReminderModal, setShowReminderModal] = useState(false);

  const [airDate, setAirDate] = useState('');
  const [responseData, setResponseData] = useState('');
  const [formattedAirDate, setFormattedAirDate] = useState('');
  const currentDate = new Date().getTime();
  const [isHovered, setIsHovered] = useState(false);
  const [addSetReminder, { isSuccess: reminderSuccess, isError: isReminderError, error: reminderError }] = useAddReminderMutation();
  const [checkForEpisode, { isLoading: episodeLoad, data: availabilityResponse }] = useEpisodeAvailabilityMutation();

  const navigate = useNavigate();
  const hasShownReminderToast = useRef(false);

  useEffect(() => {
    if (resetForm) {
      setSeasonNumber('');
      setEpisodeNumber('');
    }
    if (isReminderError) {
      if (reminderError.data.non_field_errors && reminderError.data.non_field_errors.includes("A reminder for this episode already set!!")) {
        navigate('/reminder')
      }
        // Handle other errors
        toast.error(`${Object.values(reminderError.data)}`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });

    }

     if (reminderSuccess && !hasShownReminderToast.current) {
    toast.success('Reminder set!!', {
      position: toast.POSITION.BOTTOM_CENTER,
      onClick: () => {
        navigate('/reminder');
      },
    });
    hasShownReminderToast.current = true; // Mark as shown
  }
  }, [resetForm, reminderError, reminderSuccess, isReminderError, navigate, redirectToLogin]);

  useEffect(() => {
    if (availabilityResponse) {

    }
  }, [availabilityResponse, currentDate]);

  if (!show || !show.title) {
    return null;
  }

  const checkEpisodeAvailability = async (season, episode) => {
    const payload = {
      season: season,
      episode: episode,
      title: show.title,
      kind: show.kind,
      title_id: show.imdbID,
      tmdb_id: tid,
    }
    try {
      const response = await checkForEpisode(payload).unwrap();

      const message = response.message;
      const airDate = new Date(response.airDate).getTime();

      setAvailabilityMessage(message);
      setAirDate(airDate);
      setFormattedAirDate(response.airDate);
      setResponseData(response);

      const containsNot = message.includes('Not');

      if (containsNot) {
        toast.warn(message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: true,
        });
      } else {
        toast.success(message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: true,
        });
      }

      if (airDate >= currentDate) {
        setShowReminderModal(true); // Open the reminder modal
      }
    } catch (error) {
      console.error('Failed to check episode availability:', error);
    }
  };

  const setReminder = async () => {
    const token = localStorage.getItem('aircheck_access');
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }

    const backendDate = formatDate(formattedAirDate);

    if (!token) {
      toast.error('Please Log in', {
        position: toast.POSITION.TOP_CENTER,
      });
      redirectToLogin();

      return;
    }

    const payload = {
      set_reminder: true,
      air_date: backendDate,
      temp_data: show,
      season: seasonNumber,
      episode: episodeNumber,
    }

    addSetReminder(payload);
  }

  const handleCheckAvailability = (e) => {
    e.preventDefault();
    if (seasonNumber && episodeNumber) {
      checkEpisodeAvailability(seasonNumber, episodeNumber);
    } else {
      toast.error('Please enter both season and episode numbers.', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  if (!show || !show.kind.includes('series')) {
    return (
      <>
        {token && (
          <Row className='mt-2'>
            <Col>
              <Button variant="secondary">
                <a href={`https://torrentgalaxy.to/torrents.php?search=tt${show.imdbID}&sort=seeders&order=desc`} target="_blank" rel="noopener noreferrer">Visit Torrent Galaxy</a>
              </Button>
            </Col>
          </Row>
        )}
      </>
    )
  }

  return (
    <div>
      <h3 className='m-2'>Check Episode Availability</h3>

      <Row>
        <Col>
          <Form onSubmit={handleCheckAvailability}>
            <Form.Group>
              <Form.Control
                required
                type="number"
                placeholder="Season Number"
                value={seasonNumber}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value < 1 || value > show['seasons']) {
                    e.target.setCustomValidity(`Season number must be between 1 and ${show['seasons']}`);
                  } else {
                    e.target.setCustomValidity('');
                  }
                  setSeasonNumber(value);
                }}
                min="1"
                max={show['seasons']}
              />
            </Form.Group>
            <p></p>
            <Form.Group>
              <Form.Control
                required
                type="number"
                placeholder="Episode Number"
                value={episodeNumber}
                onChange={(e) => setEpisodeNumber(e.target.value)}
                min="1"
              />
            </Form.Group>
            <p></p>
            <Button
              variant="secondary"
              disabled={episodeLoad}
              type="submit"
            >
              {episodeLoad ? 'Loading...' : 'Check Availability'}
            </Button>
          </Form>
          <p></p>
          {availabilityMessage && (
            <div>
              {airDate > currentDate && (
                <Button variant="secondary" onClick={() => setShowReminderModal(true)}>
                  <i className="bi bi-bell"></i> Set Reminder
                </Button>
              )}
            </div>
          )}

          {availabilityMessage.includes("Enjoy!!") && token && (
            <div>
              <Button variant="secondary">
                <a href={`https://torrentgalaxy.to/torrents.php?search=tt${show.imdbID}&sort=seeders&order=desc`} target="_blank" rel="noopener noreferrer">Visit Torrent Galaxy</a>
              </Button>
            </div>
          )}
        </Col>

        <Col className="episodeInfo">
  {responseData ? (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        width: "100%",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={
          !responseData["image"] || responseData["image"] === " "
            ? `${placeholderImageUrl}`
            : `${responseData["image"]}`
        }
        alt={responseData["titleText"]}
        className="img-fluid"
        style={{
          cursor: "pointer",
          maxWidth: "600px",
          maxHeight: "400px",
          width: "100%",
          height: "auto",
        }}
      />
      {isHovered && (
        <>
          <div
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              padding: "5px",
              color: "white",
              zIndex: 2,
            }}
          >
            <p style={{ margin: 0 }}>Title: {responseData["titleText"]}</p>
            <p style={{ margin: 0 }}>
              Rating:{" "}
              {responseData["extra_data"]
                ? responseData["extra_data"]["episode_rating"]
                : ""}
            </p>
            <p style={{ margin: 0 }}>
              Caption:{" "}
              {responseData["extra_data"]
                ? responseData["extra_data"]["image_caption"]
                : ""}
            </p>
          </div>
          <div
            className="plot-info"
            style={{
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              zIndex: 1,
              padding: "10px",
              position: "absolute",
              bottom: "-100px", // Default positioning for larger screens
              left: "0",
            }}
          >
            <p style={{ color: "white", margin: 0 }}>
              Plot:{" "}
              {responseData["extra_data"]
                ? decodeURIComponent(
                    responseData["extra_data"]["episode_plot"]
                  )
                : ""}
            </p>
          </div>
        </>
      )}
    </div>
  ) : (
    <>
      {/* Placeholder or alternative content */}
    </>
  )}
</Col>

      </Row>

      {showReminderModal && (
        <Modal show={showReminderModal} onHide={() => setShowReminderModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Set Reminder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Add any content you want inside the modal */}
            <p>Set a reminder on {formattedAirDate}</p>
            <p>For {show.title} Season {seasonNumber} Episode {episodeNumber}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowReminderModal(false)}>
              Close
            </Button>
            <Button variant="secondary" onClick={() => {
              setShowReminderModal(false);
              setReminder();
            }}>
              Save Reminder
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default EpisodeAvailability;
