import React, { useEffect } from 'react';
import { Container, Row, Col, Image, Button, Card } from 'react-bootstrap';
import { useNavigate } from "react-router";
import { useProfileQuery } from '../actions/apiSlice';
import { googleLogout } from '@react-oauth/google';
import { Helmet } from 'react-helmet-async';

const ProfileScreen = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('aircheck_access');

  const { data: profile, error, isLoading: profileLoad } = useProfileQuery();

  const handleLogout = () => {
    localStorage.removeItem('aircheck_access');
    googleLogout();
    navigate('/login');
  };

  const handleLogin = () => {
    navigate('/login'); // Redirect to login page
  };

  useEffect(() => {
    if (!token) {
      console.log('please login');
    }
  }, [token]);

  return (
    <>
    <Helmet>
    <title>Profile</title>

  </Helmet>
    <Container className="mt-5">
      <Card className="text-center">
        <Card.Body>
          <Row className="justify-content-center">
            <Col xs={12} md={4} className="text-center">
              {/* Avatar */}
              <Image
                src="https://via.placeholder.com/150"
                roundedCircle
                fluid
                className="mb-3"
                alt="Profile Avatar"
                style={{ width: '150px', height: '150px' }}
              />
            </Col>
            <Col xs={12} md={8} className="text-center text-md-left">
              {/* Name */}
              {token ? (
                <>
                  <h3>{profile && profile.username}</h3>
                  {/* Profile Links */}
                  <Button variant="primary" className="m-2" block onClick={() => navigate('/watchlist')}>
                    Watchlist
                  </Button>
                  <Button variant="secondary" className="m-2" block onClick={() => navigate('/reminder')}>
                    Reminder
                  </Button>
                  <Button variant="danger" className="m-2" block onClick={handleLogout}>
                    Logout
                  </Button>
                </>
              ) : (
                <>
                  {/* Show login button if token is not available */}
                  <p>Please login to access your profile</p>
                  <Button variant="primary" className="m-2" block onClick={handleLogin}>
                    Login
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
    </>
  );
};

export default ProfileScreen;
